<template>
    <div class="content_container notice_view">
        <div class="content">
            <div class="banner">
                <h1>공지사항</h1>
            </div>

            <ListBoard whichBoard="notice" />
		</div>
    </div>
</template>

<script>
import ListBoard from '@/components/ListBoard'

export default {
	name : 'Notice',
	components : {
		ListBoard,
	},
}
</script>

